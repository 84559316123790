import React from "react";
import "./enrolmentContent.css";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import { educational } from "../const";

const HSCResults2022 = () => (
    <div className="container">
        <ContentWrapper sidebar={false} narrow={false}>
            <div className={"content"}>
                <PageTitle title="2022 HSC Results" />

                <p>
                    We congratulate the class of 2022 for their commitment and hard work, we are proud of all our students.
                </p>



                <p>The outstanding highlights were:</p>

                <ul>
                    <li>Angela Sassine, College Dux – ATRAR 99.55</li>
                    <ul>
                        <li>Ranked 8th in the state for Information Processing Technology</li>
                        <li>Art Express exhibition selection for her drawing work – “The Plight of the People Series”.</li>
                    </ul>
                    <li>Pierre Doueihi – ATAR 97.40</li>
                    <li>Brandon Tran – ATAR 96.70</li>
                    <li>Paul Issa – ATAR 91.55</li>
                </ul>

                <p>
                    8 students were awarded ATARs equal to or greater than 80.
                </p>

                <p>
                    We wish them all the very best in their future endeavors.
                </p>
            </div>
            {/*<SideBar items={educational} title="Educational Excellence" />*/}
        </ContentWrapper>
    </div>
);

export default HSCResults2022;
